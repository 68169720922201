import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import BillingAddInputField from "../components/BillingAddInputField";

import PopUpModal from "../components/PopUpModal";

import { useMutation } from "react-query";
import axios from "axios";

import moment from "moment";
import Sidebar from "../components/Sidebar";
const EditBill = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [updatePopup, setUpdatePopup] = useState(null);

  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    branchNameArray[0] || ""
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const {
    currentAmount,
    rentForMonth,
    others,
    currentlyPaidAmount,
    paymentType,
    powerUsedFrom,
    powerUsedTo,
  } = watch();

  const totalAmount = `${
    Number(currentAmount) + Number(rentForMonth) + Number(others)
  }`;
  const remainingbalance = `${
    Number(totalAmount) - Number(currentlyPaidAmount)
  }`;

  useEffect(() => {
    setValue("total", totalAmount);
    setValue("remainingAmount", remainingbalance);
  }, [totalAmount, remainingbalance]);

  const { state } = useLocation();
  console.log("STATE", state);
  useEffect(() => {
    setValue("selectHostel", state?.role);
    setValue("date", state?.date);
    setValue("billNo", state?.billNo);
    setValue("name", state?.name);
    setValue("mobileNumber", state?.mobileNumber);
    setValue("email", state?.emailId);
    setValue("roomNo", state?.roomNo);
    setValue("rentForMonth", state?.rentForMonth);
    setValue("others", state?.others);
    setValue("total", state?.total);
    setValue("currentAmount", state?.currentAmount);
    setValue("paymentType", state?.paymentType);
  }, [state, setValue]);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `${
          process.env.REACT_APP_HOST
        }/newbills/updateNewBill/${selectedHostel}/${state && state.id}`,
        postdata
      ),
    {
      onSuccess: (val) => {
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const [showTransactionContainer, setShowTransactionContainer] =
    useState(false);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowTransactionContainer(false);
    }
  };
  useEffect(() => {
    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSubmit = (data) => {
    let formData;
    if (state && state.id != null && selectedHostel) {
      formData = {
        id: state.id,
        name: data?.name,
        date: moment(data?.date).format("YYYY-MM-DD"),
        mobileNumber: data?.mobileNumber,
        emailId: data?.email,
        roomNo: data?.roomNo,
        currentAmount: data?.currentAmount,
        others: data?.others,
        rentForMonth: data?.rentForMonth,
        role: selectedHostel,
        total: data?.total,
        paymentType: data?.paymentType,
        billNo: data?.billNo,
        powerUsedFrom: 0,
        powerUsedTo: 0,
        currentlypaidAmount: 0,
        // Number(data?.currentlyPaidAmount) + Number(data?.remainingAmount),
        remainingAmount: 0,
        // Number(data?.total) -
        // Number(data?.currentlyPaidAmount) -
        // Number(data?.remainingAmount),
      };
      mutate(formData);
    }
  };
  return (
    <>
      <section className="rentBillMainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0 m-0">
              <div className="new-bill-head-block">
                <div className="new-bill-heading-block">
                  <img
                    src="/assets/images/arrow-blue.png"
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h6 className="register-title pt-2">EDIT BILL</h6>
                </div>
                <div className="new-bill-search-block">
                  <Controller
                    name="selectHostel"
                    control={control}
                    defaultValue={selectedHostel}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange } }) => (
                      <select
                        value={selectedHostel}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setSelectedHostel(newValue); // Update local state
                          onChange(newValue); // Update react-hook-form field
                        }}
                        className={`selectDropDown ${
                          errors.selectHostel ? "error" : ""
                        }`}
                      >
                        <option value="">Branch Name</option>
                        {branchNameArray.map((branchName) => (
                          <option key={branchName} value={branchName}>
                            {branchName}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              </div>
              <div className="register-form-main-block">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="rentalFormBlock my-3"
                >
                  <div className=" py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Date</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                          <DatePicker
                            className="bookingDateEditInput"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            placeholderText="DD/MM/YY"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Bill No
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="billNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Bill Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                            disabled="disabled"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="name"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Customer Name"
                            type="text"
                            bookingEditInput="bookingEditInput"
                            disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Mobile Number
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="mobileNumber"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Mobile Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                            disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Email Id
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="email"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Email Id"
                            type="emailid"
                            bookingEditInput="bookingEditInput"
                            disabled="disabled"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Room No <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="roomNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Room No"
                            bookingEditInput="bookingEditInput"
                            type="text"
                            disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        EB Amount
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="currentAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="currentAmount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Rent For Month <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="rentForMonth"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Rent For Month"
                            bookingEditInput="bookingEditInput"
                            type="number"
                            disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Others</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="others"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Old Balance"
                            bookingEditInput="bookingEditInput"
                            type="number"
                            disabled="disabled"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Total</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="total"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Total"
                            bookingEditInput="bookingEditInput"
                            type="number"
                            disabled="disabled"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Payment Method
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        name="paymentType"
                        control={control}
                        defaultValue=""
                        // rules={{ required: true }}
                        render={({ field }) => (
                          <select
                            {...field}
                            value={selectedPaymentType}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSelectedPaymentType(value);
                              setShowTransactionContainer(
                                value === "UPI/BANK TRANSACTION"
                              );
                            }}
                            className="bookingEditInput"
                          >
                            <option value="">PAYMENT METHOD</option>
                            <option value="CASH PAY">CASH PAY</option>
                            <option value="UPI/BANK TRANSACTION">
                              UPI/BANK TRANSACTION
                            </option>
                          </select>
                        )}
                      />{" "}
                    </div>
                  </div>
                  <div className="py-4 px-4  billingSubmitButton">
                    <button type="submit" className="formSubmit">
                      {isLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={updatePopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => {
                  setUpdatePopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser  m-0 p-o">
                Bill has been updated successfully..
              </p>
              <button
                onClick={() => {
                  navigate("/viewBill");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
      {showTransactionContainer && (
        <div className="transaction-container" ref={containerRef}>
          UPI TRANSACTION
        </div>
      )}
    </>
  );
};

export default EditBill;
