import React, { useEffect, useState } from "react";
import BillingAddInputField from "../BillingAddInputField";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import axios from "axios";
import PopUpModal from "../PopUpModal";
import { useNavigate } from "react-router-dom";

const PopupBox = ({
  isOpen,
  onClose,
  roomNo,
  roomData,
  state,
  selectedHostel,
}) => {
  const popupRef = React.useRef(null);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  // const roomType = watch("bedRoom");
  // const kitchen = watch("kitchen");
  // const hall = watch("hall");
  console.log("roomData", roomData);
  const [bedRoom, setBedRoom] = useState("");
  const [kitchen, setKitchen] = useState("");
  const [hall, setHall] = useState("");

  const handleCheckboxChange = (fieldName, value) => {
    const currentValue = watch(fieldName); // Get the current value from the form
    if (currentValue === value) {
      setValue(fieldName, ""); // Clear the value
      if (fieldName === "bedRoom") setBedRoom("");
      else if (fieldName === "kitchen") setKitchen("");
      else if (fieldName === "hall") setHall("");
    } else {
      setValue(fieldName, value); // Set the new value
      if (fieldName === "bedRoom") setBedRoom(value);
      else if (fieldName === "kitchen") setKitchen(value);
      else if (fieldName === "hall") setHall(value);
    }
  };

  let hostelName = localStorage.getItem("hostelName");
  const [showErrorPopUp, setErrorPopUp] = useState(null);
  const [addRoomPopup, setAddRoomPopup] = useState(null);
  const [errroMessage, setErrorMessage] = useState("");
  const [updatePopup, setUpdatePopup] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setAddRoomPopup(null);
        setErrorPopUp(null);
        onClose();
        reset();
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleClickOutside);
      reset({ roomNumber: roomNo.roomNumber || "" });
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, reset]);
  const { mutate, isLoading, isError, isSuccess, data, error } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/rooms/${selectedHostel}`,
        postData
      ),
    {
      onSuccess: (data) => {
        setAddRoomPopup(1);
        reset();
      },
      onError: (error) => {
        if (error?.response) {
          // Server responded with a status code
          const { status, data } = error?.response;
          setErrorMessage(data);
          console.log("Error Response:", data);
          setErrorPopUp(1);
        } else {
          console.log("Unexpected Error:", error?.message);
        }
      },
    }
  );

  const onSubmit = (data) => {
    let formData = {
      roomNo: data?.roomNumber,
      noOfBeds: data?.NoOfBeds,
      ebNumber: data?.ebNumber,
      bedRoom: data?.bedRoom,
      kitchen: data?.kitchen,
      hall: data?.hall,
      branchName: roomData?.branchName,
      noOfFloors: roomData?.noOfFloor,
      selectFloor: roomData?.selectedFloors,
      noOfRooms: roomData?.noOfRooms,
      noOfPortion: roomData?.portion,
      selectPortion: roomData?.selectPortion,
      roomType: "",
      selectHostel: roomData?.hostel,
      foodOption: "",
    };

    if (formData) {
      mutate(formData);
    }
    // Reset the fields after submission
    reset({
      bedRoom: "",
      kitchen: "",
      hall: "",
    });

    // Reset local state variables
    setBedRoom("");
    setKitchen("");
    setHall("");
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="popup-overlay">
        <div className="new-room-popup-content" ref={popupRef}>
          <div className="close-icon-block" onClick={onClose}>
            <img
              src="/assets/images/close.png"
              alt="Close"
              className="search-icon"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 popup-room-title">ROOM DETAILS</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row px-1 my-2 d-flex align-items-center">
                <div className="col-12 col-sm-6 col-md-2 my-1 px-1">
                  <label className="popup-label">
                    ROOM NUMBER{" "}
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                  </label>
                </div>
                <div className="col-12 col-sm-6 col-md-4 my-1 p-0">
                  <Controller
                    control={control}
                    name="roomNumber"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="Room Number"
                        type="text"
                        popupInputField="popup-input-field"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-2 my-1 px-1">
                  <label className="popup-label">
                    NO OF BEDS{" "}
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                  </label>
                </div>
                <div className="col-12 col-sm-6 col-md-4 my-1 p-0">
                  <Controller
                    control={control}
                    name="NoOfBeds"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="Sharing"
                        type="number"
                        popupInputField="popup-input-field"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className="row px-1 my-2 d-flex align-items-center">
                <div className="col-12 col-sm-6 col-md-2 my-1 px-1">
                  <label className="popup-label">EB NUMBER </label>
                </div>
                <div className="col-12 col-sm-6 col-md-4 my-1 p-0">
                  <Controller
                    control={control}
                    name="ebNumber"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="EB Number"
                        type="text"
                        popupInputField="popup-input-field"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row my-2 px-1">
                <div className="col-12">
                  <label className="popup-label --new-room-type">
                    ROOM TYPE{" "}
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                  </label>
                </div>
              </div>
              <div className="row mt-4 mb-1 px-5">
                <div className="col-12 col-sm-6 col-md-4">
                  <label className="popup-label --room-type mb-2">
                    BED ROOM
                  </label>
                  <div className="d-flex flex-column gap-1">
                    <Controller
                      name="bedRoom"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <label className="popup-room-content">
                            <input
                              type="checkbox"
                              checked={bedRoom === "A/C"}
                              onChange={() =>
                                handleCheckboxChange("bedRoom", "A/C")
                              }
                            />
                            A/C
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              checked={bedRoom === "Non-A/C"}
                              onChange={() =>
                                handleCheckboxChange("bedRoom", "Non-A/C")
                              }
                            />
                            Non-A/C
                          </label>
                        </>
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <label className="popup-label --room-type mb-2">
                    KITCHEN
                  </label>
                  <div className="d-flex flex-column gap-1">
                    <Controller
                      name="kitchen"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <label className="popup-room-content">
                            <input
                              type="checkbox"
                              checked={kitchen === "A/C"}
                              onChange={() =>
                                handleCheckboxChange("kitchen", "A/C")
                              }
                            />
                            A/C
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              checked={kitchen === "Non-A/C"}
                              onChange={() =>
                                handleCheckboxChange("kitchen", "Non-A/C")
                              }
                            />
                            Non-A/C
                          </label>
                        </>
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <label className="popup-label --room-type mb-2">HALL</label>
                  <div className="d-flex flex-column gap-1">
                    <Controller
                      name="hall"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <label className="popup-room-content">
                            <input
                              type="checkbox"
                              checked={hall === "A/C"}
                              onChange={() =>
                                handleCheckboxChange("hall", "A/C")
                              }
                            />
                            A/C
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              checked={hall === "Non-A/C"}
                              onChange={() =>
                                handleCheckboxChange("hall", "Non-A/C")
                              }
                            />
                            Non-A/C
                          </label>
                        </>
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-3 px-1">
                <div className="col-12 d-flex justify-content-center">
                  <button type="submit" className="new-room-submit-btn">
                    {/* SUBMIT */}
                    {isLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <PopUpModal isOpen={addRoomPopup == null} ref={popupRef}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setAddRoomPopup(null);
              onClose();
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContent p-2">Thank you!</span>
          <span className="popUpContentPara pb-2">
            Room added successfully..
          </span>
          <button
            onClick={() => {
              setAddRoomPopup(null); // Close the modal
              onClose();
              reset();
              navigate("/"); // Then navigate to home
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={showErrorPopUp == null} ref={popupRef}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setErrorPopUp(null);
              onClose();
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContentPara pt-3 pb-2">
            {errroMessage && errroMessage}
          </span>
          <button
            onClick={() => {
              setErrorPopUp(null);
              onClose();
              reset();
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default PopupBox;
