import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import html2pdf from "html2pdf.js";
import { useLocation, useNavigate } from "react-router-dom";
const BillingInvoice = () => {
  const { state } = useLocation();
  const objdata = Object.assign({}, state?.userdata);

  const navigate = useNavigate();
  const downloadPDF = () => {
    const invoiceContent = document.getElementById("invoice").innerHTML;

    // Define options for the PDF generation
    const opt = {
      margin: 0.5,
      filename: "billing_invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Use html2pdf library to generate PDF from HTML content
    html2pdf().from(invoiceContent).set(opt).save();
  };
  let billInfo = localStorage.getItem("billInfo");
  let role = localStorage.getItem("role");
  let billingdetails = JSON.parse(billInfo);

  return (
    <>
      <section className="billingInvoiceSection">
        <div className="container">
          <div className="row px-4 m-0">
            <div className="col-lg-12 invoice-download-block">
              <div className="d-flex align-items-center">
                <img
                  src="/assets/images/arrow-blue.png"
                  className="arrow-img-icon"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <h6 className="register-title pt-2">INVOICE</h6>
              </div>
              <button
                className="btn bg-primary text-white"
                onClick={downloadPDF}
              >
                Download
              </button>
            </div>
            <div className="col-lg-12" id="invoice">
              <div className="pageSize">
                <div className="billing-invoice-logo-block">
                  <div className="billing-logo">
                    {/* <img
                      src="/assets/images/smlogo.png"
                      className="image_icon"
                      alt="no-image"
                    /> */}
                    <img
                      src="/assets/images/pg.jpg"
                      className="img-fluid image_icon"
                      alt="no-image"
                    />
                  </div>
                </div>
                <div className="billing-invoice-info-block">
                  <h1>{objdata[0]?.shopName}</h1>
                  <p className="billing-invoice-para">
                    {objdata[0]?.registerNumber}
                  </p>
                  <p className="billing-invoice-para">
                    {objdata[0]?.companyAddress}
                  </p>
                  <p className="billing-invoice-para">
                    {objdata[0]?.companyLocation}
                    {objdata[0]?.companyPincode}
                  </p>
                </div>
              </div>
              <div className="mt-3 logoBlock">
                <div className="billing-invoice-stu-block">
                  <div>
                    <h1>INVOICE</h1>
                  </div>
                  <div className="d-flex flex-column">
                    <div>
                      <span className="invoicePara">Bill.No : </span>
                      <span className="invoice_Para">
                        {state?.item?.billNo}
                      </span>
                    </div>
                    <div>
                      <span className="invoicePara">Name : </span>
                      <span className="invoice_Para">{state?.item?.name}</span>
                    </div>
                    <div>
                      <span className="invoicePara">Date : </span>
                      <span className="invoice_Para">{state?.item?.date}</span>
                    </div>

                    <div>
                      <span className="invoicePara">RoomNo : </span>
                      <span className="invoice_Para">
                        {state?.item?.roomNo}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="billing-invoice-acc-block">
                  {/* <div>
                    <span className="invoicePara">Bank Name : </span>
                    <span className="invoice_Para">
                      {billingdetails?.bankName}
                    </span>
                  </div>
                  <div>
                    <span className="invoicePara">Account Name :</span>
                    <span className="invoice_Para">
                      {" "}
                      {billingdetails?.accountName}
                    </span>
                  </div>
                  <div>
                    <span className="invoicePara">Account Type :</span>
                    <span className="invoice_Para">
                      {billingdetails?.accountType}
                    </span>
                  </div>
                  <div>
                    <span className="invoicePara">Account Number :</span>
                    <span className="invoice_Para">
                      {billingdetails?.accountNumber}
                    </span>
                  </div>
                  <div>
                    <span className="invoicePara">IFSC Code : </span>
                    <span className="invoice_Para">
                      {billingdetails?.ifsccode}
                    </span>
                  </div>
                  <div>
                    <span className="invoicePara">Branch : </span>
                    <span className="invoice_Para">
                      {billingdetails?.branch}
                    </span>
                  </div> */}
                  <img
                    src="/assets/images/qrscanner.jfif"
                    style={{
                      width: "20%",
                      height: "20%",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    className="img-fluid"
                    alt="no-image"
                  />
                </div>
              </div>
              <div className="tableData py-5">
                <TableContainer>
                  <Table>
                    <TableHead className="billingInvoiceHeader">
                      <TableRow>
                        <TableCell className="billingInvoiceHeading">
                          RoomNo
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Name
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Rent For Month
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Power Used
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Others
                        </TableCell>
                        <TableCell className="billingInvoiceHeading">
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <TableRow>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.roomNo}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.name}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.rentForMonth}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.currentAmount}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.others}
                          </TableCell>
                          <TableCell className="billingInvoiceData">
                            {state?.item?.total}
                          </TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="d-flex pt-5 my-4 px-2 justify-content-end">
                <p className="invoice_Para">Authorised by</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BillingInvoice;
