import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PopupBox from "../popup/PopupBox";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";

const NewRoomForm = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { roomType: [] },
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    branchNameArray[0] || ""
  );
  const [noOfFloors, setNoOfFloors] = useState(0);
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedPortion, setSelectedPortion] = useState("");
  const [floorPortionCounts, setFloorPortionCounts] = useState({});
  const [roomCounts, setRoomCounts] = useState({});

  const branchName = watch("branchName");
  const noOfFloor = watch("noOfFloors");
  const selectedFloors = watch("selectFloors");
  const noOfRooms = watch("noOfRooms");
  const portion = watch("noOfPortion");
  const selectPortion = watch("selectPortion");
  const hostel = watch("selectHostel");

  const [popupState, setPopupState] = useState({
    isOpen: false,
    successPopup: false,
    errorPopup: false,
    roomData: null,
    roomNo: {},
  });

  // Fetch the number of floors based on the selected hostel
  const getNoOfFloors = async () => {
    if (!selectedHostel) return;
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/lastnooffloors/${selectedHostel}`
    );
    return response.data;
  };

  // Fetch the number of portions based on the selected hostel and floor
  const getNoOfPortions = async () => {
    if (!selectedHostel || !selectedFloor) return;
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/lastnoofPortionsbyfloor/${selectedHostel}/${selectedFloor}`
    );
    return response.data;
  };

  // Fetch the number of rooms based on the selected hostel and portion
  const getNoOfRooms = async () => {
    if (!selectedHostel || !selectPortion) return;
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/lastnoofRoomsbyselectPortion/${selectedHostel}/${selectPortion}`
    );
    return response.data;
  };

  const { data: noOfFloorsData } = useQuery(
    ["getNoOfFloors", selectedHostel],
    getNoOfFloors,
    {
      enabled: !!selectedHostel, // Trigger the API only when selectedHostel is available
    }
  );

  const { data: noOfPortionsData } = useQuery(
    ["getNoOfPortions", selectedHostel, selectedFloor],
    getNoOfPortions,
    {
      enabled: !!selectedHostel && !!selectedFloor, // Trigger the API when both selectedHostel and selectedFloor are available
    }
  );

  const { data: noOfRoomsData } = useQuery(
    ["getNoOfRooms", selectedHostel, selectPortion],
    getNoOfRooms,
    {
      enabled: !!selectedHostel && !!selectPortion, // Trigger the API when both selectedHostel and selectPortion are available
    }
  );

  const handleOpenPopup = (roomNumber) => {
    setPopupState({
      isOpen: true,
      roomData: {
        branchName,
        noOfFloor,
        selectedFloors,
        noOfRooms,
        portion,
        selectPortion,
        hostel
      },
      roomNo: { roomNumber },
    });
  };

  const handleClosePopup = () => {
    setPopupState({
      isOpen: false,
      roomData: null,
      roomNo: {},
    });
  };

  const generateFloorOptions = () => {
    const floorOptions = [];
    for (let i = 0; i < noOfFloors; i++) {
      floorOptions.push(`Floor ${i + 1}`);
    }
    return floorOptions;
  };

  const numberToRoman = (num) => {
    const romanNumerals = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
    };
    return romanNumerals[num] || "";
  };

  const generatePortionOptions = () => {
    const portionCount = Math.min(floorPortionCounts[selectedFloor] || 0, 10);

    const portionOptions = [];
    for (let i = 1; i <= portionCount; i++) {
      portionOptions.push(numberToRoman(i));
    }

    return portionOptions;
  };

  useEffect(() => {
    if (noOfFloorsData) {
      setValue("noOfFloors", noOfFloorsData); // Set floor value from API data
      setNoOfFloors(noOfFloorsData); // Local state for noOfFloors
    }
  }, [noOfFloorsData, setValue]);

  useEffect(() => {
    if (noOfPortionsData !== undefined) {
      setValue("noOfPortion", noOfPortionsData || 0); // Set portion count from API
      setFloorPortionCounts((prev) => ({
        ...prev,
        [selectedFloor]: noOfPortionsData || 0,
      }));
    }
  }, [noOfPortionsData, selectedFloor, setValue]);

  useEffect(() => {
    if (noOfRoomsData !== undefined) {
      setValue("noOfRooms", noOfRoomsData || 0); // Set room count from API
      setRoomCounts((prev) => ({
        ...prev,
        [`${selectedFloor}-${selectedPortion}`]: noOfRoomsData || 0,
      }));
    }
  }, [noOfRoomsData, selectedFloor, selectedPortion, setValue]);

  // Reset `noOfRooms`, `selectedFloor`, and `selectedPortion` when `selectedHostel` changes
  useEffect(() => {
    if (selectedHostel) {
      setValue("noOfRooms", 0); // Reset the rooms count
      setRoomCounts({}); // Reset room counts
      setSelectedFloor(""); // Reset selected floor
      setSelectedPortion(""); // Reset selected portion
    }
  }, [selectedHostel, setValue]);

  return (
    <>
      <div className="row pt-2 pb-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-6 col-md-6">
          <div className="new-register-title-block">
            <img
              src="/assets/images/arrow-blue.png"
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">NEW ROOM</h5>
          </div>
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-between justify-content-md-end align-items-center gap-2">
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDown ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
          <button
            className="view-register-btn"
            onClick={() => {
              navigate("/newRoom");
            }}
          >
            VIEW ROOM
          </button>
        </div>
      </div>

      {/* The rest of your component remains unchanged */}
      <div className="new-room-form-main-block">
        <div className="new-room-form-block my-3">
          <div className="row mx-4 p-0 mt-3 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                BRANCH NAME{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="branchName"
                control={control}
                defaultValue="JSK WOMENS PG"
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <select
                    {...field}
                    className={`newRoomInputField ${
                      errors.branchName ? "error" : ""
                    }`}
                  >
                    <option value="">Branch Name</option>
                    <option value="JSK WOMENS PG">JSK WOMENS PG</option>
                  </select>
                )}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                NO OF FLOORS{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfFloors"
                control={control}
                defaultValue={noOfFloors}
                render={({ field }) => (
                  <input
                    className="newRoomInputField"
                    placeholder="No Of Floors"
                    type="number"
                    min="1"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      setNoOfFloors(count);
                      field.onChange(count);
                      setFloorPortionCounts({});
                      setRoomCounts({});
                      setSelectedFloor(""); // Reset selected floor
                      setSelectedPortion(""); // Reset selected portion
                    }}
                    value={field.value}
                  />
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
          </div>

          <div className="row mx-4 p-0 mt-3 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                SELECT FLOOR{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="selectFloors"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    className="newRoomInputField"
                    onChange={(e) => {
                      const floor = e.target.value;
                      setSelectedFloor(floor); // Set selected floor
                      setSelectedPortion(""); // Reset portion when floor changes
                      field.onChange(floor);
                    }}
                    value={field.value}
                  >
                    <option value="">Select Floor</option>
                    {generateFloorOptions().map((floor) => (
                      <option key={floor} value={floor}>
                        {floor}
                      </option>
                    ))}
                  </select>
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">
                NO OF PORTIONS{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfPortion"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <input
                    placeholder="No of Portions"
                    className="newRoomInputField"
                    type="number"
                    min="0"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      setFloorPortionCounts((prev) => ({
                        ...prev,
                        [selectedFloor]: count,
                      }));
                      field.onChange(count); // Update the form state
                    }}
                    value={field.value || 0}
                  />
                )}
              />
            </div>
          </div>

          <div className="row mx-4 p-0 mt-3 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                SELECT PORTION{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="selectPortion"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    className="newRoomInputField"
                    onChange={(e) => {
                      const portion = e.target.value;
                      setSelectedPortion(portion);
                      field.onChange(portion);
                    }}
                    value={field.value}
                  >
                    <option value="">Select Portion</option>
                    {selectedFloor &&
                      generatePortionOptions().map((portion) => (
                        <option key={portion} value={portion}>
                          {portion}
                        </option>
                      ))}
                  </select>
                )}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">
                NO OF ROOMS{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfRooms"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <input
                    placeholder="No of Rooms"
                    className="newRoomInputField"
                    type="number"
                    min="0"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      setRoomCounts((prev) => ({
                        ...prev,
                        [`${selectedFloor}-${selectedPortion}`]: count,
                      }));
                      field.onChange(count);
                    }}
                    value={field.value || 0}
                  />
                )}
              />
            </div>
          </div>
          <div className="row mx-5 p-0 mt-4 mb-2">
            <div className="col-12 newRoomInputBlockContainer">
              <p className="new-room-para pt-2">
                <span>SELECTED FLOOR: {selectedFloor || "None"}</span>
                <span>SELECTED PORTION: {selectedPortion || "None"}</span>
              </p>
              <div className="row">
                {/* Display Rooms based on the number of rooms entered */}
                {roomCounts[`${selectedFloor}-${selectedPortion}`] > 0 &&
                  [
                    ...Array(roomCounts[`${selectedFloor}-${selectedPortion}`]),
                  ].map((_, index) => (
                    <div
                      key={`${selectedFloor}-${selectedPortion}-room-${index}`}
                      className="col-12 col-sm-6 col-md-3 my-1 d-flex justify-content-center"
                    >
                      <div
                        className="room-container"
                        onClick={() => {
                          handleOpenPopup(
                            `F${selectedFloor.replace(
                              "Floor ",
                              ""
                            )} - ${selectedPortion} - ${index + 1}`
                          );
                        }}
                      >
                        {`F${selectedFloor.replace(
                          "Floor ",
                          ""
                        )} - ${selectedPortion} - ${index + 1}`}
                      </div>
                    </div>
                  ))}
                {roomCounts[`${selectedFloor}-${selectedPortion}`] === 0 &&
                  selectedPortion && <p>No rooms assigned for this portion.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupBox
        isOpen={popupState.isOpen}
        onClose={handleClosePopup}
        roomData={popupState.roomData}
        roomNo={popupState.roomNo}
        state={state}
        selectedHostel={selectedHostel}
      />
    </>
  );
};

export default NewRoomForm;
