import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../components/BillingAddInputField";
import DatePicker from "react-datepicker";
import moment from "moment";
import PopUpModal from "../components/PopUpModal";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Sidebar from "../components/Sidebar";

const NewRegister = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    branchNameArray[0] || ""
  );

  const [count, setCount] = useState(0);

  const [addRegisterPopup, setRegisterPopup] = useState(null);
  const [roomStatus, setRoomStatus] = useState(null);
  const [errroMessage, setErrorMessage] = useState("");
  const [showErrorPopUp, setErrorPopUp] = useState(null);
  // console.log("HOSTEL NAME : ", hostelName);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const getViewRoom = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/getAllNewRoom/${selectedHostel}`
    );
  };
  const getViewBedNo = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}/lastBedNo`
    );
  };
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("data added successfully...");
        setRegisterPopup(1);
      },
      onError: (error) => {
        if (error?.response) {
          // Server responded with a status code
          const { status, data } = error?.response;
          setErrorMessage(data);
          console.log("Error Response:", data);
          setErrorPopUp(1);
        } else {
          console.log("Unexpected Error:", error?.message);
        }
      },
    }
  );
  const { data } = useQuery(["getViewRoom", selectedHostel], getViewRoom, {
    enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
  });
  let details = data && data?.data;
  // console.log("details", details);
  const { data: bednumber } = useQuery(
    ["getViewBedNo", selectedHostel],
    getViewBedNo,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );
  let registerBedNo = bednumber && bednumber?.data;
  let roomNumber = details?.map((dd, i) => {
    return dd?.roomNo;
  });
  // console.log("ROOMNUMBER", roomNumber);
  useEffect(() => {
    setValue("roomNo", state?.roomNo);
    setValue("bedNo", registerBedNo);
  });
  const onSubmit = (data) => {
    let formData = {
      name: data?.name,
      mobileNo: data?.mobileNo,
      emailId: data?.emailId,
      roomNo: data?.roomNo,
      emergencyNo: data?.emergencyNo,
      dateOfBirth: moment(data?.dateOfBirth).format("YYYY-MM-DD"),
      dateOfJoining: moment(data?.dateOfJoining).format("YYYY-MM-DD"),
      address: data?.address,
      bedNo: data?.bedNo,
      status: data?.status,
      selectHostel: data?.selectHostel,
      selectFoodOption: data?.selectFoodOption,
      advanceAmount: data?.advancePayment,
      rentForMonth: data?.rentForMonth,
      proffesionType: data?.proffesionType,
      aadharNumber: data?.aadharNumber,
      parentialOrGuardian: data?.parentialOrGuardian,
      familyNumber: data?.familyNumber,
      // localGuardianNumber: data?.localGuardianNumber,
    };
    if (Array.isArray(roomNumber) && formData?.roomNo != null) {
      const isAvailable = roomNumber?.includes(formData?.roomNo);

      if (isAvailable && selectedHostel) {
        mutate(formData);
      } else {
        setRoomStatus(1);
      }
    }
  };

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <section className="registrationMainContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10  px-0  m-0">
              <div className="row pt-2 pb-3 px-2 m-0 new-register-heading-block">
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="new-register-title-block">
                    <img
                      src="/assets/images/arrow-blue.png"
                      alt="no-image"
                      className="arrow-img-icon"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <h5 className="register-title pt-2">NEW REGISTER</h5>
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-between justify-content-md-end align-items-center gap-2">
                  <Controller
                    name="selectHostel"
                    control={control}
                    defaultValue={selectedHostel}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange } }) => (
                      <select
                        value={selectedHostel}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setSelectedHostel(newValue); // Update local state
                          onChange(newValue); // Update react-hook-form field
                        }}
                        className={`selectDropDown ${
                          errors.selectHostel ? "error" : ""
                        }`}
                      >
                        <option value="">Branch Name</option>
                        {branchNameArray.map((branchName) => (
                          <option key={branchName} value={branchName}>
                            {branchName}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <button
                    className="view-register-btn"
                    onClick={() => {
                      navigate("/viewRegister");
                    }}
                  >
                    VIEW REGISTER
                  </button>
                </div>
              </div>

              <div className="register-form-main-block">
                <div className="register-form-block my-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          NAME <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="name"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Name"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          EMAIL ID
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="emailId"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Email Id"
                                type="emailid"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          MOBILE NUMBER <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="mobileNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Mobile Number"
                                type="tele"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ALTERNATIVE NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="emergencyNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Alternative Number"
                                type="tele"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          PROFESSION TYPE
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="proffesionType"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Profession Type"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          AADHAR NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="aadharNumber"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Aadhar Number"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          PARENT/GUARDIAN
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="parentialOrGuardian"
                            control={control}
                            defaultValue="Father/Mother" // Set the  default value here
                            render={({ field }) => (
                              <select {...field} className="bookingEditInput">
                                <option value="Father/Mother">
                                  Father/Mother
                                </option>
                                <option value="Local Guardian">
                                  Local Guardian
                                </option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      {watch("parentialOrGuardian") === "Father/Mother" ? ( // Corrected this line
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              FATHER/MOTHER NUMBER
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="familyNumber"
                                render={(field) => (
                                  <BillingAddInputField
                                    {...field}
                                    placeholder="Father/Mother Number"
                                    type="tel" // Changed to "tel" for telephone input
                                    newRegisterInput="newRegisterInput"
                                  />
                                )}
                                // rules={{ required: true }} // Uncomment if you want to enforce this field as required
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="register-container">
                            <label className="BillingAddInputLabel --new-register-label-two">
                              LOCAL GUARDIAN NUMBER
                            </label>
                            <div className="newRegisterInputBlockTwo">
                              <Controller
                                control={control}
                                name="familyNumber"
                                render={(field) => (
                                  <BillingAddInputField
                                    {...field}
                                    placeholder="Guardian Number"
                                    type="tel" // Changed to "tel" for telephone input
                                    newRegisterInput="newRegisterInput"
                                  />
                                )}
                                // rules={{ required: true }} // Uncomment if you want to enforce this field as required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          DATE OF BIRTH
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="dateOfBirth"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <DatePicker
                                className={`newRegisterInput ${
                                  errors.dateOfBirth ? "error" : ""
                                }`} // Conditional class for error
                                selected={value ? new Date(value) : null}
                                onChange={(date) => {
                                  onChange(
                                    date
                                      ? moment(date).format("YYYY-MM-DD")
                                      : ""
                                  ); // Format and set value
                                }}
                                onBlur={onBlur} // Handle blur for validation
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy" // Format displayed in the date picker
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          DATE OF JOINING{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="dateOfJoining"
                            render={({ field }) => (
                              <DatePicker
                                // className="newRegisterInput"
                                className={`newRegisterInput ${
                                  errors.dateOfJoining ? "error" : ""
                                }`} // Conditional class for error
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                // dateFormat="YYYY/MM/DD"
                                // dateFormat='Pp/'
                                placeholderText="DD/MM/YY"
                                minDate={new Date(getTodayDate())} // Disable previous days
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ROOM NO <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="roomNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Room No"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          BED NUMBER
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="bedNo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Bed Number"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          SELECT FOOD OPTION{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="selectFoodOption"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Food option is required" }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.selectFoodOption ? "error" : ""
                                }`}
                              >
                                {" "}
                                {/* Conditional class for error */}
                                <option value="">Select Food</option>
                                <option value="With Food">With Food</option>
                                <option value="Without Food">
                                  Without Food
                                </option>
                                <option value="Own Cooking">Own Cooking</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADVANCE PAYMENT{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="advancePayment"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Advance Payment"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          RENT FOR MONTH <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="rentForMonth"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Rent For Month"
                                type="number"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          ADDRESS
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            control={control}
                            name="address"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Address"
                                type="text"
                                newRegisterInput="newRegisterInput"
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-container">
                        <label className="BillingAddInputLabel --new-register-label-two">
                          STATUS
                        </label>
                        <div className="newRegisterInputBlockTwo">
                          <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                              <select
                                {...field}
                                className={`newRegisterInput ${
                                  errors.status ? "error" : ""
                                }`}
                              >
                                <option value="">Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-register-input-block">
                      <div className="register-btn-block ">
                        <button className="register-submit-btn">
                          {isLoading ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={addRegisterPopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => {
                  setRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent p-2">Thank you!</span>
              <span className="popUpContentPara pb-3">
                User added successfully..
              </span>
              <button
                onClick={() => {
                  navigate("/viewRegister");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={roomStatus === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={() => {
                  setRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              {/* <span className="popUpContent p-2">Thank you!</span> */}
              <span className="popUpContentPara pb-3 pt-4">
                Room Not Available Please Check Available Room !!
              </span>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={showErrorPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContentPara pt-3 pb-2">
            {errroMessage && errroMessage}
          </span>
          <button
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default NewRegister;
